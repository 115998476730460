<template>
  <v-row v-if="true" dense no-gutters class="mx-n2 justify-center">
    <v-col cols="12" md="6" height="100%">
      <scroller
        :canLoadMore="!canLoadMore"
        :items="items"
        @reachedEnd="reachedEnd"
        @remove="(itm) => handleRemove(itm)"
        @removed="(itm) => handleRemove(itm)"
      />
    </v-col>
    <v-col v-if="items.length < 1">
      <v-card-title class="justify-center">
        {{ $lang("PostSomething") }}
      </v-card-title>
    </v-col>
    <fab-button
      @clicked="handleFabClick"
      icon="mdi-plus"
      color="primary white--text"
    />
    <new-feed-post-dialog
      :show="showNewDialog"
      @closed="(val) => (showNewDialog = val)"
      @submitted="handleNewSubmit"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import bus from "@/bus";
import scroller from "./scroller.vue";
import FabButton from "@/components/FabButton";
import NewFeedPostDialog from "./NewFeedPostDialog";

export default {
  components: { scroller, FabButton, NewFeedPostDialog },
  name: "feed",
  data() {
    return {
      benched: 0,
      items: [],
      page: 1,
      lastPage: 1,
      canLoadMore: true,
      showNewDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    "authUser.id"(val) {},
  },
  methods: {
    handleRemove(item) {
      console.log("removing..............", item);
      this.items = this.items.filter((el) => {
        return el.id != item.id;
      });
    },
    handleFabClick() {
      this.showNewDialog = true;
    },
    handleNewSubmit(data) {
      console.log("handle new post....");
      this.items = [];
      this.page = 1;
      setTimeout(() => {
        this.loadMoreFromApi();
      }, 1200);
    },
    reachedEnd() {
      this.loadMoreFromApi();
    },
    fetchItems(page = 1) {
      return axios
        .get(
          "feed/mine/and/approved?orderby=created_at|desc&perPage=10&page=" +
            page
        )
        .then((res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          this.canLoadMore = false;
          return Promise.reject(err);
        });
    },
    loadMoreFromApi() {
      if (this.page > this.lastPage) {
        return;
      }
      return this.fetchItems(this.page)
        .then((data) => {
          this.lastPage = data.lastPage;
          this.items.push(...data.data);
          this.page++;
        })
        .catch((err) => {
          this.canLoadMore = false;
        });
    },
  },
  mounted() {
    // this.loadMoreFromApi();
  },
};
</script>

